import React from "react";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet";

import { useDrop } from "react-dnd";
import useStore, { useReportData, useUserData } from "../stores/reportStore";

import { mapEyvaUserToVeltUser, calculateMaxScale } from "../utils/helpers";

import ViewReportVerticalScroll from "../components/ui/ViewReportVerticalScroll";

import Loading from "../components/ui/Loading";
import NoAccess from "../components/ui/NoAccess";

import Toolbar from "../components/ui/toolbar/Toolbar";

import {
    useVeltClient,
    VeltComments,
    VeltCommentsSidebar,
    VeltCursor,
} from "@veltdev/react";
import { RequireAuth } from "../components/ui/RequireAuth";

function Report() {
    const { showComments, showCursors, setScale } = useStore();
    const { client } = useVeltClient();

    const { reportId } = useParams();

    const {
        isLoading: reportLoading,
        error: reportError,
        reportData,
        reportStatus,
        pageTitle,
    } = useReportData();

    const { userLoading, userError, isLoggedIn, userInfo } = useUserData();

    const isLoading = useMemo(
        () => userLoading || reportLoading,
        [userLoading, reportLoading],
    );

    useEffect(() => {
        const handleResize = () => {
            const maxScale = calculateMaxScale();
            setScale(maxScale);
        };

        // Calculate initial scale based on window size
        const initialScale = calculateMaxScale();
        setScale(initialScale);

        // This should be done before fetching report
        // If report status is PROCESSING and user is logged in => show loading with type="processing"
        // If report is not public and user is logged in => just show
        // If report is not public and user is not logged in => show NoAccess Component
        // If report is public and user is not logged in => show, but collab is disabled
        // fetchReportInfo();

        // const jwtToken = getJwtToken(); // Replace 'jwt' with the actual cookie name

        // fetchUser({ jwtToken });

        // Add the event listener for the 'resize' event
        window.addEventListener("resize", handleResize);

        // Return a cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // useEffect for velt
    useEffect(() => {
        if (!userInfo) return;
        if (client && userInfo?.loggedInUser) {
            const authenticatedUser = mapEyvaUserToVeltUser(
                userInfo.loggedInUser,
            );

            authenticatedUser.contacts = userInfo.contacts.map((contact) =>
                mapEyvaUserToVeltUser(contact),
            );

            console.log({ authenticatedUser });

            // replaceContacts: hammer method! will replace the contacts list with the one you provide
            client.identify(authenticatedUser, { replaceContacts: true });

            client.setDocumentId(reportId);
        }
    }, [client, userInfo]);

    useEffect(() => {
        console.log("showComments changed:", showComments);
        console.log("showCursors changed:", showCursors);
    }, [showComments, showCursors]);

    console.log({ reportStatus, reportData, reportError: reportError });

    console.log("userError", { userError }, { reportError });

    const isUnauthorized = reportError?.response?.status === 401;

    if (isUnauthorized) {
        return <RequireAuth />;
    }

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {isLoading ? (
                <Loading />
            ) : reportData?.reportStatus !== "DONE" ? (
                <Loading type="processing" />
            ) : (
                <>
                    <VeltCursor />
                    <VeltComments
                        commentsOnDom={showComments}
                        commentTool={showComments}
                    />
                    <VeltCommentsSidebar />

                    <Container />
                </>
            )}
        </>
    );
}

export const Container = () => {
    const [boxes, setBoxes] = useState({
        bottom: 0,
        left: Math.round((window.innerWidth - 315) / 2),
    });
    const moveBox = useCallback(
        (left, bottom) => {
            setBoxes({ left, bottom });
        },
        [boxes, setBoxes],
    );
    const [, drop] = useDrop(
        () => ({
            accept: "box",
            drop(item, monitor) {
                const delta = monitor.getDifferenceFromInitialOffset();
                const left = Math.round(item.left + delta.x);
                const bottom = Math.round(item.bottom - delta.y);
                moveBox(left, bottom);
                return undefined;
            },
        }),
        [moveBox],
    );
    return (
        <div
            ref={drop}
            className="flex flex-col items-center w-full min-h-screen pt-20 overflow-y-auto text-white bg-black pb-28"
        >
            <ViewReportVerticalScroll />
            <Toolbar left={boxes["left"]} bottom={boxes["bottom"]} />
        </div>
    );
};

export default Report;
