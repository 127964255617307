import React, { useEffect, useMemo, useState } from "react";
import {
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import Footer from "../_generic/Footer";
import { Table } from "../_generic/Table";
import ShowAllButton from "../_generic/ShowAllButton";
import { Layout } from "../_generic/Layout";
import { formatNumber, formatNumberCompact } from "../../../utils/formatNumber";
import { classNames } from "../../../utils/classNames";
export const Overview = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let { h1 = "ERROR", note, datePeriod, rows = [] } = slideContent;

    const columns = React.useMemo(
        () => [
            {
                id: "srno",
                size: 10,
                header: () => <p className="text-center">#</p>,
                cell: (props) => {
                    const i =
                        props?.table
                            ?.getSortedRowModel()
                            ?.flatRows?.indexOf(props?.row) + 1;
                    return <>{i}</>;
                },
            },
            {
                header: () => <p className="text-center">PP</p>,
                accessorKey: "imgURL",
                size: 80,
                enableSorting: false,
                meta: { disablePadding: true },
                cell: (cell) => {
                    const img = cell.getValue();
                    return (
                        <img
                            className="-my-2 mx-auto border border-gray-50 text-center rounded-full"
                            src={img ?? "/no-img-found.png"}
                            alt="product"
                            style={{
                                width: "40px",
                                height: "40px",
                            }}
                        />
                    );
                },
            },
            {
                accessorKey: "account",
                header: "Account",
                enableSorting: false,
                meta: { className: "text-left" },
                cell: ({ getValue, row }) => (
                    <a
                        className="line-clamp-1 truncate underline underline-offset-1"
                        href={`https://www.instagram.com/${getValue()}/`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        @{getValue()}
                    </a>
                ),
            },
            {
                accessorKey: "followers",
                header: "Total Followers",
                size: 30,
                enableSorting: false,
                meta: {
                    className: "text-left",
                    headerClassName: "whitespace-nowrap",
                },
                cell: ({ getValue }) => formatNumberCompact(getValue()),
            },
            {
                accessorKey: "newFollowers",
                header: "Followers Change",
                size: 30,
                meta: {
                    className: "text-left bg-gray-100",
                    headerClassName: "text-left whitespace-nowrap",
                },
                cell: ({ getValue }) => {
                    const growth = getValue();
                    return (
                        <div
                            className={classNames(
                                growth > 0
                                    ? "text-green-700"
                                    : growth < 0
                                    ? "text-red-700"
                                    : "",
                                "flex items-center space-x-0.5 text-sm font-medium",
                            )}
                        >
                            {growth == null ? (
                                <>&#x2015;</>
                            ) : (
                                <p>
                                    {growth > 0 && "+"}
                                    {formatNumber(growth)}
                                </p>
                            )}
                        </div>
                    );
                },
            },
            {
                accessorKey: "engagement",
                header: "E.R.",
                size: 30,
                meta: {
                    className: "text-left",
                    headerClassName: "text-left whitespace-nowrap",
                },
                cell: ({ getValue }) => {
                    const engagement = getValue();
                    return `${Math.round(engagement * 100) / 100}%`;
                },
            },
            {
                accessorKey: "likes",
                header: "Avg. Likes",
                size: 30,
                meta: {
                    className: "text-left",
                    headerClassName: "text-left  whitespace-nowrap",
                },
                cell: ({ getValue }) => formatNumberCompact(getValue()),
            },
            {
                accessorKey: "comments",
                header: "Avg. Comments",
                size: 30,
                meta: {
                    className: "text-left",
                    headerClassName: "text-left  whitespace-nowrap",
                },
                cell: ({ getValue }) => formatNumberCompact(getValue()),
            },
            // {
            //     accessorKey: "reels",
            //     header: "No of Reels",
            //     size: 30,
            //     meta: {
            //         className: "text-left",
            //         headerClassName: "text-left  whitespace-nowrap",
            //     },
            //     cell: ({ getValue }) => formatNumberCompact(getValue()),
            // },
            {
                accessorKey: "reelViews",
                header: "Avg. Reel Views",
                size: 30,
                meta: {
                    className: "text-left",
                    headerClassName: "text-left  whitespace-nowrap",
                },
                cell: ({ getValue }) => formatNumberCompact(getValue()),
            },
        ],
        [],
    );
    const maxCount = rows.length;

    const minCount = 7;

    const totalCount = rows.length;

    const [displayCount, setDisplayCount] = useState(minCount);

    useEffect(() => {
        if (showFull) {
            setDisplayCount(maxCount);
        }
    }, []);

    const data = useMemo(
        () => rows.slice(0, displayCount),
        [rows, displayCount],
    );

    const table = useReactTable({
        data: data,
        columns,

        defaultColumn: {
            size: 400, //starting column size
            minSize: 0, //enforced during column resizing
            maxSize: 800, //enforced during column resizing
        },
        enableSortingRemoval: false,
        enableMultiSort: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    return (
        <Layout showFull={showFull}>
            <div className="p-10 space-y-8 w-full min-h-[690px]">
                <div>
                    <h1 className="text-black text-17xl font-semibold leading-[54px]">
                        {h1}
                    </h1>
                    <h2 className="text-gray-500 text-sm">{note}</h2>
                    <h2 className="text-sm">{datePeriod}</h2>
                </div>
                <div className="w-full text-[#11131A] font-normal text-sm">
                    <Table table={table} />
                </div>

                <div className="flex w-full justify-center">
                    <ShowAllButton
                        displayCount={displayCount}
                        maxCount={maxCount}
                        handleShowAll={() => setDisplayCount(totalCount)}
                        totalCount={totalCount}
                    ></ShowAllButton>
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default Overview;
