import React, { useState } from "react";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import { SocialMediaPost } from "../_generic/SocialMediaPost";

export const InstaPosts = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    let {
        h1 = "ERROR",
        data = [],
        dataTitle = "ERROR",
        topic = "ERROR",
    } = slideContent;

    const formattedPosts =
        data?.map((post) => ({
            username: post.handle,
            userAvatar: post.profileImageURL,
            imageUrl: post.postImageURL,
            caption: post.caption,
            date: post.date,
            engagementRate: post.engagementRate,
            likes: post.likes,
            comments: post.comments,
            views: post.plays,
            externalUrl: post.externalURL,
        })) ?? [];

    const DISPLAY_COUNT = 4;
    const [displayCount, setDisplayCount] = useState(DISPLAY_COUNT);

    const handleShowMore = () => {
        setDisplayCount((prev) =>
            Math.min(prev + DISPLAY_COUNT, formattedPosts.length),
        );
    };

    return (
        <Layout showFull={showFull}>
            <div className="text-black w-full min-h-[690px] bg-zinc-100 py-10 px-12 font-poppins">
                {formattedPosts.length !== 0 ? (
                    <>
                        <h1 className="text-black text-4xl font-medium">
                            {h1}
                        </h1>
                        <p className="mt-4 text-sm text-eyvablue font-semibold uppercase">
                            <span className="text-black">{topic}: </span>
                            {dataTitle}
                        </p>
                        <div className="flex flex-col gap-7 items-center justify-center">
                            <div className="grid grid-cols-4 gap-4 text-black mt-10">
                                {formattedPosts
                                    .slice(0, displayCount)
                                    .map((post, index) => (
                                        <SocialMediaPost
                                            key={index}
                                            post={post}
                                            platform="instagram"
                                        />
                                    ))}
                            </div>
                            {displayCount < formattedPosts.length && (
                                <button
                                    className="bg-purple text-white px-9 py-3 rounded-sm font-medium text-sm font-poppins"
                                    onClick={handleShowMore}
                                >
                                    Show More
                                </button>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="flex flex-col">
                        <div className="flex flex-col mb-5 ">
                            <h1 className="text-black text-4xl font-medium">
                                {h1}
                            </h1>
                            <p className="mt-4 text-sm text-eyvablue font-semibold uppercase">
                                <span className="text-black">{topic}: </span>
                                {dataTitle}
                            </p>
                        </div>

                        <div className="flex flex-col col-span-5 h-[505px] bg-[#F1F1F1] border border-[#D5D5D5] rounded-md items-center justify-center">
                            <p className="text-black text-sm font-semibold mb-2">
                                No Posts For This Topic
                            </p>
                            <p className="text-[#3A3A3A] text-sm font-normal w-[428px] text-center">
                                Currently, the accounts we are tracking do not
                                have any posts related to this topic.
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default InstaPosts;
