import ReactEcharts from "echarts-for-react";

import Footer from "../_generic/Footer";

import { Layout } from "../_generic/Layout";
import { formatNumberCompact } from "../../../utils/formatNumber";
import { GrowthText } from "../_generic/GrowthText";
import { classNames } from "../../../utils/classNames";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
const colors = {
    one: "#EB966E",
    two: "#9A2888",
    three: "#272090",
};

const getOptions = ({ data, lang = "en" }) => {
    // Find the reference item
    const refItem = data.find((item) => item.ref);
    const maxValue = refItem
        ? refItem.searchVolume
        : Math.max(...data.map((item) => item.searchVolume));

    // Calculate relative values without changing order
    const relativeData = data.map((item) => ({
        ...item,
        relativeValue: item.searchVolume / maxValue,
    }));

    // Find the largest non-ref value while maintaining original order
    const largestNonRefItem = relativeData
        .filter((item) => !item.ref)
        .reduce((prev, current) =>
            prev.searchVolume > current.searchVolume ? prev : current,
        );

    // Calculate the ratio
    const ratio = largestNonRefItem.searchVolume / refItem.searchVolume;

    // Construct dynamic xAxisText
    let comparison;
    let ratioText;

    if (ratio > 1) {
        comparison = "greater than for ";
        ratioText = `${Number(ratio.toFixed(2))}x`;
    } else if (ratio < 1) {
        comparison = "less than for ";
        ratioText = `${Number((1 / ratio).toFixed(2))}x`;
    } else {
        comparison = "equal to ";
        ratioText = "";
    }

    let xAxisText = `The monthly search volume (sv) for ${largestNonRefItem.topic} is \n${ratioText} ${comparison}${refItem.topic}`;

    if (lang === "de") {
        if (ratio > 1) {
            comparison = "größer";
            ratioText = `um das ${Number(ratio.toFixed(2))}-fache`;
        } else if (ratio < 1) {
            comparison = "geringer";
            ratioText = `um das ${Number((1 / ratio).toFixed(2))}-fache`;
        } else {
            comparison = "gleich";
            ratioText = "";
        }

        xAxisText = `Das monatliche Suchvolumen (sv) für ${largestNonRefItem.topic} ist \n${ratioText} ${comparison} als für ${refItem.topic}`;
    }

    return {
        animation: false,
        grid: {
            left: "-20px", // Adjust as needed
            right: "10px", // Adjust as needed
            top: "50px", // Adjust as needed
            bottom: "80px", // Adjust as needed
            containLabel: true,
        },
        xAxis: {
            type: "category",
            data: relativeData.map((item) => item.topic),
            name: xAxisText,
            nameLocation: "middle",
            nameGap: 60,
            nameTextStyle: {
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Poppins",
                lineHeight: 21,
                color: "#3A3A3A",
            },
            axisTick: {
                show: false, // Hide the axis ticks
            },
            axisLabel: {
                fontFamily: "Poppins", // Set the font family for x-axis labels
                fontStyle: "normal", // You can set it to 'italic' or 'normal'
                fontSize: 14, // Set the font size
                lineHeight: 21,
                fontWeight: 400,
                color: "#3A3A3A",
                // You can add more styling properties as needed
            },
        },
        yAxis: {
            show: false,
        },
        series: [
            {
                data: relativeData.map((item) => ({
                    value: item.relativeValue, // This is the relative value
                    itemStyle: {
                        color: item.itemColor,
                    },
                    label: {
                        show: true,
                        position: "top",
                        distance: 10,
                        formatter: function (params) {
                            // params.dataIndex gives the index of the data item
                            const actualValue =
                                relativeData[params.dataIndex].searchVolume;
                            const formattedValue =
                                formatNumberCompact(actualValue);

                            // Format the relative value to remove unnecessary trailing zeros
                            const relativeValueText =
                                params.value === 1
                                    ? "1x"
                                    : `${Number(params.value.toFixed(2))}x`; // Using Number() removes trailing zeros

                            return `{large|${relativeValueText}}\n(sv = {normal|${formattedValue}})`;
                        },
                        rich: {
                            large: {
                                fontSize: 14,
                                lineHeight: 21,
                                fontWeight: 400,
                                align: "center",
                                fontFamily: "Poppins",
                                color: "#3A3A3A",
                            },
                            normal: {
                                fontSize: 12,
                                align: "center",
                                fontWeight: 400,
                                fontStyle: "italic",
                                fontFamily: "Poppins",
                                lineHeight: 18,
                                color: "#3A3A3A",
                            },
                        },
                    },
                })),
                type: "bar",
            },
        ],
    };
};

const About = ({
    slideContent,
    slideName,
    reportName,
    reportCreatedAt,
    reportVersion,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    const {
        data = [],
        paragraphs = [],
        h1 = "ERROR",
        title = "ERROR",
        topic = "ERROR",
        comparisonNote = "ERROR",
        comparisonTitle = "ERROR",
    } = slideContent;

    for (let i = 0; i < data.length; i++) {
        if (i === 0) data[i].itemColor = colors.three;
        else if (i === 1) data[i].itemColor = colors.two;
        else data[i].itemColor = colors.one;
    }

    const { searchVolume, yoy = null } = data.filter((d) => d.ref)[0];

    return (
        <Layout showFull={showFull}>
            <div className="w-full h-[720px] font-poppins">
                <div
                    className={classNames(
                        "grid gap-12 h-full",
                        data.length > 1 && "grid-cols-2",
                    )}
                >
                    <div className="flex flex-col col-span-1 ml-10 space-y-10">
                        <div className="flex flex-col mt-10 space-y-2">
                            <h1 className="text-black text-[34px] font-medium ">
                                {h1}
                            </h1>
                            <h2 className="text-eyvablue text-sm font-semibold uppercase">
                                {title}: &nbsp;
                                <span className="text-black">{topic}</span>
                            </h2>
                        </div>

                        <div className="flex items-center text-black text-xs font-normal gap-10">
                            <div>
                                <p className="text-2xl font-medium">
                                    {formatNumberCompact(searchVolume)}
                                </p>
                                <p className="text-sm font-normal">
                                    Search Volume
                                </p>
                            </div>
                            <div>
                                <GrowthText
                                    growth={yoy}
                                    className="text-2xl font-medium"
                                />
                                <p className="text-sm font-normal">
                                    YoY Growth
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 overflow-y-scroll text-black max-h-[380px]  mb-5 pr-5">
                            {paragraphs.map((p) => (
                                <div key={p.header}>
                                    <h3 className="text-[20px] font-semibold">
                                        {p.header}
                                    </h3>
                                    <div className="text-[15px] font-normal leading-[33px] font-poppins markdown !max-w-none prose-h1:text-base">
                                        <ReactMarkdown
                                            remarkPlugins={[remarkGfm]}
                                        >
                                            {p.paragraph}
                                        </ReactMarkdown>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {data.length > 1 && (
                        <div className="col-span-1 bg-zinc-100">
                            <div className="flex-col items-start self-stretch justify-center mt-14 mx-12">
                                <h3 className="text-[20px] font-semibold text-black mb-28">
                                    {comparisonTitle}
                                </h3>

                                <div>
                                    <ReactEcharts
                                        option={getOptions({ data })}
                                        opts={{ renderer: "svg" }}
                                        style={{
                                            width: "500px",
                                            height: "300px",
                                        }}
                                    ></ReactEcharts>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportCreatedAt={reportCreatedAt}
                reportVersion={reportVersion}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default About;
